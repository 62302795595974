<template>
    <Overlay @fechaOverlay="$emit('fechaOverlay')">
        <Card>
            <form class="form" @submit.prevent="salvaNovoBloqueio()">
                <div :class="{ error: codigoInvalido }">
                    <label for="codigo">Codigo:</label>
                    <input type="number" id="codigo" name="codigo" step="1" v-model="codigo" />
                </div>

                <div>
                    <label for="status">Status:</label>
                    <select name="status" id="status" @change="statusHandler">
                        <option v-for="status in todosStatus" :key="status.status" :value="status.status">
                            {{ status.status }}
                        </option>
                    </select>
                </div>

                <small class="form_error-msg" v-if="codigoInvalido">Codigo não pode ser vazio.</small>

                <Button texto="Salvar" />
            </form>
        </Card>
    </Overlay>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';
import Overlay from '../UI/Overlay.vue';

export default {
    components: {
        Card,
        Button,
        Overlay,
    },

    props: {
        todosStatus: {
            required: true,
        },
    },

    data() {
        return {
            codigo: '',
            codigoInvalido: false,
            status: '',
        };
    },

    created() {
        this.status = this.todosStatus[0].status
    },

    watch: {
        codigo(newValue) {
            if (newValue != '') {
                this.codigoInvalido = false;
            }
        },
    },

    methods: {
        salvaNovoBloqueio() {
            if (this.validaCriacao() == false) return;

            const produto = {
                codigo_de_barras: this.codigo,
                status: this.status,
            };

            this.$emit('salvaNovoBloqueio', produto);
        },

        statusHandler(event) {
            this.status = event.target.value;
        },

        validaCriacao() {
            if (this.codigo == '') {
                this.codigoInvalido = true;
                return false;
            }

            return true;
        },
    },
};
</script>
