<template>
    <Card>
        <div class="prod">
            <div>
                <img :src="img" @error="replaceImg" />
            </div>

            <div class="prod_text">
                <div>
                    <strong>Codigo de Barras:</strong>
                    <p>{{ codigo }}</p>
                </div>

                <div>
                    <strong>Status:</strong>
                    <p>{{ status }}</p>
                </div>
            </div>

            <div class="prod_button">
                <Button texto="X" :isRemove="true" @click="removerProduto()" />
            </div>
        </div>
    </Card>
</template>

<script>
import Card from '../UI/Card.vue';
import Button from '../UI/Button.vue';
import img from '../../assets/images/broken-image.svg';

export default {
    components: {
        Card,
        Button,
    },

    props: {
        codigo: {
            required: true,
        },
        status: {
            required: true,
        },
        img: {
            required: true,
            type: String,
        },
    },

    methods: {
        removerProduto() {
            this.$emit('removerProduto', this.codigo);
        },

        replaceImg(e) {
            e.target.src = img;
        },
    },
};
</script>

<style lang="scss" scoped>
.prod {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    place-items: center;

    strong {
        margin-bottom: 5px;
        display: inline-block;
    }

    &_button {
        width: 40px;
    }

    img {
        max-width: 80px;
        max-height: 80px;
    }

    &_text {
        display: grid;
        gap: 10px;
    }
}
</style>
