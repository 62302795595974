<template>
    <div class="container">
        <Title titulo="Produtos Bloqueados" />

        <div class="navigate">
            <SearchInput texto="Pesquisar codigo" @changeInput="searchText" />
            <Button texto="Filtros" @click="showFilter = !showFilter" />
            <Button texto="Novo bloqueio" @click="isCreating = true" />
        </div>

        <FiltroBloqueio @filtraProdutos="filtraProdutos" :showFilter="showFilter" />

        <Spinner v-if="isLoading" />

        <template v-else>
            <div class="paginate">
                <div class="paginate_results">
                    {{ (currentPage - 1) * itensPerPage }} -
                    {{ currentPage * itensPerPage >= totalProdutos ? totalProdutos : currentPage * itensPerPage }} de
                    {{ totalProdutos }} resultados
                </div>

                <div class="paginate_buttons">
                    <div>
                        <button @click="prevPageHandler">
                            <img src="../assets/images/chevron-left.svg" alt="left arrow" />
                        </button>
                    </div>

                    <div>{{ currentPage }} de {{ totalPages }}</div>

                    <div>
                        <button @click="nextPageHandler">
                            <img src="../assets/images/chevron-right.svg" alt="right arrow" />
                        </button>
                    </div>
                </div>
            </div>

            <div class="produtos">
                <transition-group name="fade" mode="out-in">
                    <BloqueadoItem
                        v-for="produto in produtos"
                        :key="produto.codigo_de_barras"
                        :codigo="produto.codigo_de_barras"
                        :status="produto.status"
                        :img="produto.img"
                        @removerProduto="removerProduto"
                    />
                </transition-group>
            </div>
        </template>

        <transition name="fade" mode="out-in">
            <NovoBloqueio
                v-if="isCreating"
                :todos-status="todosStatus"
                @fechaOverlay="fechaOverlay()"
                @salvaNovoBloqueio="salvaNovoBloqueio"
            />
        </transition>

        <Modal v-if="showModal" :isError="isError" :mensagem="modalMessage" @closeModal="showModal = false" />
    </div>
</template>

<script>
import Title from '../components/UI/Title.vue';
import SearchInput from '../components/SearchInput.vue';
import Modal from '../components/Modal.vue';
import Button from '../components/UI/Button.vue';
import Spinner from '../components/UI/Spinner.vue';
import BloqueadoItem from '../components/Bloqueados/BloqueadoItem.vue';
import NovoBloqueio from '../components/Bloqueados/NovoBloqueio.vue';
import FiltroBloqueio from '../components/Bloqueados/FiltroBloqueio.vue';
import axios from 'axios';

export default {
    components: {
        Title,
        SearchInput,
        Button,
        Spinner,
        BloqueadoItem,
        NovoBloqueio,
        Modal,
        FiltroBloqueio,
    },

    data() {
        return {
            isEditing: false,
            isCreating: false,
            produtos: [],
            showModal: false,
            isError: false,
            modalMessage: '',
            showFilter: false,
            user: null,
            totalPages: null,
            currentPage: 1,
            itensPerPage: 30,
            totalProdutos: null,
            isLoading: true,
            todosStatus: [],
            headers: null,
        };
    },

    computed: {
        getProdutos() {
            return this.$store.getters['bloqueados/getProdutos'];
        },
        getProdutosFiltrados() {
            return this.$store.getters['bloqueados/getProdutosFiltrados'];
        },
        nextPage() {
            return parseInt(this.currentPage) + 1;
        },
        prevPage() {
            return this.currentPage - 1;
        },
    },

    created() {
        this.user = JSON.parse(sessionStorage.getItem('user'));
        this.headers = { Accept: 'application/json', authorization: 'Bearer ' + this.user.token };

        const requests = [
            this.axios.get(`${this.user.cliente}/bloqueados`, { headers: this.headers }),
            this.axios.get(`${this.user.cliente}/bloqueados/status`, { headers: this.headers }),
        ];

        axios
            .all(requests)
            .then(
                axios.spread((bloqueados, status) => {
                    const todosProdutos = bloqueados.data;
                    this.$store.dispatch('bloqueados/setProdutos', todosProdutos);
                    this.$store.dispatch('bloqueados/setProdutosFiltrados', todosProdutos);

                    this.todosStatus = status.data;

                    this.getBloqueadosPages(todosProdutos);
                })
            )
            .catch(() => {
                this.isError = true;
                this.showModal = true;
                this.modalMessage =
                    'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    beforeRouteUpdate(to) {
        this.getBloqueadosPages(this.getProdutosFiltrados, to.params.page);
    },

    beforeRouteEnter(to, from, next) {
        const permissoes = JSON.parse(sessionStorage.getItem('permissoes'));

        if (permissoes.permissoes.tela_bloqueio == 0) {
            next({ path: '/usuarios' });
        } else {
            next();
        }
    },

    methods: {
        nextPageHandler() {
            if (this.nextPage <= this.totalPages) {
                this.$router.push({ path: '/bloqueados/' + this.nextPage });
            }
        },

        prevPageHandler() {
            if (this.prevPage > 0) {
                this.$router.push({ path: '/bloqueados/' + this.prevPage });
            }
        },

        salvaNovoBloqueio(produto) {
            this.axios
                .post(`${this.user.cliente}/bloqueados`, produto, {
                    headers: this.headers,
                })
                .then((response) => {
                    this.produtos.unshift(response.data);
                    this.totalProdutos += 1;

                    this.isError = false;
                    this.modalMessage = 'Bloqueio cadastrado com sucesso.';
                })
                .catch((error) => {
                    const response = error.response;
                    this.isError = true;

                    if (response.status == 406) {
                        this.modalMessage = response.data;
                    } else {
                        this.modalMessage =
                            'Sua solicitação não pôde ser realizada no momento, tente novamente mais tarde, ou caso o erro persista entre em contato com o suporte.';
                    }
                })
                .finally(() => {
                    this.isCreating = false;
                    this.showModal = true;
                });
        },

        getBloqueadosPages(produtos, pageFilter = '') {
            let page;

            if (pageFilter) {
                page = pageFilter;
            } else {
                page = this.$route.params.page;

                if (!page) {
                    page = 1;
                }
            }

            this.totalProdutos = produtos.length;
            this.totalPages = Math.ceil(produtos.length / this.itensPerPage);

            this.currentPage = page;

            const min = (page - 1) * this.itensPerPage;
            const max = this.itensPerPage * page;

            let items = produtos.slice(min, max);

            this.produtos = items;
        },

        searchText(codigo) {
            if (codigo.trim().length <= 0) {
                this.getBloqueadosPages(this.getProdutosFiltrados);
            } else {
                const produtosFiltrados = this.getProdutos.filter((produto) => {
                    return produto.codigo_de_barras.toString().includes(codigo);
                });
                
                this.$store.dispatch('bloqueados/setProdutosFiltrados', produtosFiltrados);

                this.getBloqueadosPages(produtosFiltrados, 1);
            }
        },

        filtraProdutos(filtros) {
            const produtosFiltrados = this.getProdutos.filter((produto) => {
                return (
                    produto.categoria_2.toString().includes(filtros.categoria_2) &&
                    produto.categoria_3.toString().includes(filtros.categoria_3) &&
                    produto.marca.toString().includes(filtros.marca)
                );
            });

            this.$store.dispatch('bloqueados/setProdutosFiltrados', produtosFiltrados);

            this.getBloqueadosPages(produtosFiltrados, 1);

            this.showFilter = false;
        },

        fechaOverlay() {
            this.isCreating = false;
            this.showModal = false;
        },

        removerProduto(codigo) {
            this.axios
                .delete(`${this.user.cliente}/bloqueados`, {
                    headers: this.headers,
                    data: { codigo_de_barras: codigo },
                })
                .then(() => {
                    this.produtos = this.produtos.filter((produto) => {
                        return produto.codigo_de_barras !== codigo;
                    });

                    this.totalProdutos -= 1;

                    this.$store.dispatch('bloqueados/removeProduto', codigo);
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.navigate {
    display: grid;
    gap: 30px;
    margin-bottom: 50px;

    @media (min-width: 768px) {
        gap: 50px;
        grid-template-columns: 350px 150px 150px;
    }
}

.produtos {
    display: grid;
    gap: 20px;

    @media (min-width: 768px) {
        justify-content: space-between;
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: 1600px) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.paginate {
    display: grid;
    gap: 20px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 1.1rem;
    }

    &_buttons {
        display: flex;
        place-items: center;
        gap: 10px;
        background-color: #fff;
        box-shadow: 0 0 2px #9e9e9e;
        border-radius: 3px;
        width: fit-content;

        button {
            cursor: pointer;
            border: none;
            background-color: transparent;
            display: flex;
            padding: 4px 6px;
            transition: 0.2s ease-in-out;

            &:hover {
                background-color: rgba($color: #000, $alpha: 0.1);
            }
        }

        div:first-child button {
            border-right: 1px solid #ddd;

            &:hover {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
            }
        }

        div:last-child button {
            border-left: 1px solid #ddd;

            &:hover {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
            }
        }
    }
}
</style>
