<template>
    <div class="filtros">
        <div class="filtros_opcoes" v-if="showFilter">
            <form @submit.prevent="filtraProdutos()">
                <div>
                    <label for="categoria_2">Categoria 2:</label>
                    <select name="categoria_2" id="categoria_2" @change="categoria2Handler">
                        <option value="">Selecione a categoria</option>
                        <option v-for="categoria in getCategorias2" :key="categoria" :value="categoria">
                            {{ categoria }}
                        </option>
                    </select>
                </div>

                <div>
                    <label for="categoria_3">Categoria 3:</label>
                    <select name="categoria_3" id="categoria_3" @change="categoria3Handler">
                        <option value="">Selecione a categoria</option>
                        <option v-for="categoria in getCategorias3" :key="categoria" :value="categoria">
                            {{ categoria }}
                        </option>
                    </select>
                </div>

                <div>
                    <label for="marca">Marca:</label>
                    <select name="marca" id="marca" @change="marcaHandler">
                        <option value="">Selecione a marca</option>
                        <option v-for="marca in getMarcas" :key="marca" :value="marca">{{ marca }}</option>
                    </select>
                </div>

                <Button texto="Filtrar" />
            </form>
        </div>

        <div class="filtros_ativos" v-show="hasAnyFilter">
            <h3>Filtros ativos:</h3>
            <div>
                <button v-show="inputCategoria2 && hasFilter" @click="limpaCategoria2()">
                    <strong>Categoria 2</strong>: {{ inputCategoria2 }}
                </button>
                <button v-show="inputCategoria3 && hasFilter" @click="limpaCategoria3()">
                    <strong>Categoria 3</strong>: {{ inputCategoria3 }}
                </button>
                <button v-show="inputMarca && hasFilter" @click="limpaMarca()">
                    <strong>Marca</strong>: {{ inputMarca }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '../UI/Button.vue';

export default {
    components: {
        Button,
    },

    props: {
        showFilter: {
            required: true,
        },
    },

    data() {
        return {
            inputCategoria2: '',
            inputCategoria3: '',
            inputMarca: '',
            hasFilter: false,
        };
    },

    computed: {
        getCategorias2() {
            return this.$store.getters['bloqueados/getCategorias2'];
        },
        getCategorias3() {
            return this.$store.getters['bloqueados/getCategorias3'];
        },
        getMarcas() {
            return this.$store.getters['bloqueados/getMarcas'];
        },

        hasAnyFilter() {
            if (
                this.hasFilter &&
                (this.inputCategoria2 !== '' || this.inputCategoria3 !== '' || this.inputMarca !== '')
            ) {
                return true;
            } else {
                return false;
            }
        },
    },

    methods: {
        filtraProdutos() {
            const filtros = {
                categoria_2: this.inputCategoria2,
                categoria_3: this.inputCategoria3,
                marca: this.inputMarca,
            };

            this.hasFilter = true;

            this.$emit('filtraProdutos', filtros);
        },

        categoria2Handler(event) {
            this.inputCategoria2 = event.target.value;

            this.limpaFiltro();

            this.$store.dispatch('bloqueados/setCategoria2', event.target.value);
        },

        categoria3Handler(event) {
            this.inputCategoria3 = event.target.value;

            this.limpaFiltro();

            this.$store.dispatch('bloqueados/setCategoria3', event.target.value);
        },

        marcaHandler(event) {
            this.inputMarca = event.target.value;

            this.limpaFiltro();
        },

        limpaFiltro() {
            if (this.inputCategoria2 == '' && this.inputCategoria3 == '' && this.inputMarca == '') {
                this.hasFilter = false;
            }
        },

        limpaCategoria2() {
            this.inputCategoria2 = '';
            this.filtraProdutos();
        },

        limpaCategoria3() {
            this.inputCategoria3 = '';
            this.filtraProdutos();
        },

        limpaMarca() {
            this.inputMarca = '';
            this.filtraProdutos();
        },
    },
};
</script>

<style lang="scss" scoped>
.filtros {
    display: grid;
    gap: 20px;
    margin-bottom: 30px;

    @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
    }

    &_opcoes {
        border-radius: 10px;
        padding: 25px;
        box-shadow: 0 0 2px #696969;
        background-color: #fff;

        @media (min-width: 768px) {
            width: 350px;
        }

        form {
            display: grid;
            gap: 20px;

            label {
                display: block;
                margin-bottom: 7px;
                font-size: 1.1rem;
                font-weight: bold;
            }

            select {
                width: 100%;
                padding: 5px;
                border-radius: 4px;
            }
        }
    }

    &_ativos {
        h3 {
            margin-bottom: 20px;
            font-size: 1.4rem;
        }

        div {
            display: flex;
            gap: 15px;
            flex-wrap: wrap;
        }

        button {
            padding: 10px 15px;
            background-color: #fff;
            border: none;
            border-radius: 4px;
            box-shadow: 0 0 2px #696969;
            cursor: pointer;
        }
    }
}
</style>
